.ant-layout-sider {
    background: url(../../images/siderbg.png) no-repeat;
    background-size: 100% 100%;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: transparent;
}

.sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 9;
}

.logo {
    margin: 25px 0;
    width: 160px;
}

.logo-small {
    margin: 25px 0;
    width: 30px;
}

.ant-menu-submenu-title,
.ant-menu-item {
    line-height: 60px!important;
    height: 60px!important;
}

.logoutBox {
    position: absolute;
    bottom: 70px;
    left: 40px;
    right: 40px;
    border: 1px solid #fff;
    color: #fff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.logoutBox svg {
    margin-top: -5px;
}

.ant-menu-submenu >a,
.ant-menu-item > a {
    font-size: 16px;
}



.alignCenter {
    vertical-align: middle;
}

.fs-16 {
    font-size: 16px;
}

.ant-layout-sider-trigger {
    background-color: transparent;
}

.nav-item-title {
    width: 80px;
    display: inline-block;
    text-align: left;
}

.nav-sub-menu {
    background-color: transparent;
}

.nav-sub-menu-collapsed {
    background-color: #0D1626 !important;
}
