.pageMask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pageMask.hide {
    display: none;
}

.pageMask .loadingIcon {
    font-size: 70px;
}

.pageMask .loadingContent{
    text-align: center;
}

.pageMask p {
    margin-top: 30px;
    font-size: 18px;
}